import ListingCounts from 'app/shared/models/ListingCounts';

interface FactsProps {
  foreclosuresPerHousehold?: number;
  listingCounts?: Record<string, unknown>;
  medianAge?: number;
  medianHouseholdIncome?: number;
  medianRent?: number;
  medianRentAsPercentOfIncome?: number;
  perCapitaIncome?: number;
  percentRenters?: number;
  population?: number;
  populationDensity?: number;
  squareMiles?: number;
  diffFromLastMonth?: number;
  avgRent?: number;
}

class Facts {
  foreclosuresPerHousehold: number;
  listingCounts: ListingCounts;
  medianAge: number;
  medianHouseholdIncome: number;
  medianRent: number;
  medianRentAsPercentOfIncome: number;
  perCapitaIncome: number;
  percentRenters: number;
  population: number;
  populationDensity: number;
  squareMiles: number;
  diffFromLastMonth: number;
  avgRent: number;

  constructor({
    foreclosuresPerHousehold = 0,
    listingCounts = {},
    medianAge = 0,
    medianHouseholdIncome = 0,
    medianRent = 0,
    medianRentAsPercentOfIncome = 0,
    perCapitaIncome = 0,
    percentRenters = 0,
    population = 0,
    populationDensity = 0,
    squareMiles = 0,
    diffFromLastMonth = 0,
    avgRent = 0,
  }: FactsProps = {}) {
    // delete after api has stopped returning null values: https://jira.hotterpads.com/browse/HP-3871
    listingCounts = listingCounts !== null ? listingCounts : {};

    this.foreclosuresPerHousehold = Number(foreclosuresPerHousehold);
    this.listingCounts = new ListingCounts(listingCounts);
    this.medianAge = Number(medianAge);
    this.medianHouseholdIncome = Number(medianHouseholdIncome);
    this.medianRent = Number(medianRent);
    this.medianRentAsPercentOfIncome = Number(medianRentAsPercentOfIncome);
    this.perCapitaIncome = Number(perCapitaIncome);
    this.percentRenters = Number(percentRenters);
    this.population = Number(population);
    this.populationDensity = Number(populationDensity);
    this.squareMiles = Number(squareMiles);
    this.diffFromLastMonth = Number(diffFromLastMonth);
    this.avgRent = Number(avgRent);
  }
}

export default Facts;
