import Facts from 'app/shared/models/Facts';

interface FactsByBedsParams {
  [key: string]: any;
}

class FactsByBeds {
  [key: number]: typeof Facts.prototype;

  constructor(factsByBeds: FactsByBedsParams = {}) {
    Object.keys(factsByBeds).forEach((key) => {
      const beds = parseInt(key, 10);
      if (Number.isInteger(beds) && beds >= 0) {
        this[beds] = new Facts(factsByBeds[key]);
      }
    });
  }
}

export default FactsByBeds;
