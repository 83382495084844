// @ts-nocheck
/* eslint-enable */
import Promise from 'promise';
import assign from 'lodash/assign';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import api from 'app/shared/utils/api';
import AppActions from 'app/shared/flux/actions/AppActions';
import Area from 'app/shared/models/Area';
import AreaBoundaryCache from 'app/shared/cache/areaBoundaryCache';
import Article from 'app/shared/models/Article';
import StateSitemap from 'app/shared/models/StateSitemap';
import constants from 'app/shared/constants/ConstantsBundle';
import Facts from 'app/shared/models/Facts';
import FactsByBeds from 'app/shared/models/FactsByBeds';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import GeoJson from 'app/shared/models/GeoJson';
import processAreasResponse from 'app/shared/utils/processAreasResponse';
import RelatedAreas from 'app/shared/models/RelatedAreas';
import { adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';
import { DEFAULT } from 'app/shared/models/Filter';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('actions/area');

const areaCache = {
  area: {
    'new-york-ny': {
      id: '117776782',
      resourceId: 'new-york-ny',
      name: 'New York',
      fullName: 'New York, NY',
      type: 'city',
      uriV2: '/new-york-ny/apartments-for-rent',
      state: 'NY',
      city: 'New York',
      zip: '',
      neighborhood: '',
      county: '',
      breadcrumbs: [],
      coordinates: {
        lon: -73.979681,
        lat: 40.697488,
      },
      minLat: 40.477399,
      maxLat: 40.917577,
      minLon: -74.25909,
      maxLon: -73.700272,
    },
  },
  within: {},
  facts: {},
  factsByBeds: {},
  articles: {},
};

const MAX_LISTINGS_PER_SITEMAP_PAGE = 50;
const MF_FILTER = {
  propertyTypes: {
    any: false,
    apartment: true,
    condo: false,
    duplex: false,
    house: false,
    townhouse: false,
  },
  orderBy: 'weekViews',
};

const fromCache = function (type, key) {
  const cacheType = areaCache[type];
  let cachedItem;

  if (cacheType) {
    cachedItem = cacheType[key];
    return cachedItem;
  }
};

const setCache = function (type, key, value) {
  const cacheType = areaCache[type];

  if (cacheType) {
    cacheType[key] = value;
  }
};

const AreaActions = {
  clearCurrentArea() {
    return (dispatch) => {
      dispatch({ type: constants.CLEAR_CURRENT_AREA });
    };
  },
  getAreaByResourceId(areaResourceId) {
    return function (dispatch) {
      const key = areaResourceId;
      const area = fromCache('area', key);

      if (!areaResourceId) {
        return Promise.resolve(false);
      }

      if (area) {
        return Promise.resolve(area);
      }

      return dispatch(api.area.byResourceId(areaResourceId)).then((res) => {
        if (res.data && res.data.resourceId) {
          return new Area(res.data);
        } else {
          return Promise.resolve(false);
        }
      });
    };
  },
  getAreaByLatLon(options) {
    return function (dispatch) {
      const opts = options || {};
      const lat = opts.lat;
      const lon = opts.lon;
      const areaTypeMustMatch = opts.areaTypeMustMatch || ['city', 'county', 'neighborhood', 'borough', 'zip', 'state'];

      if (Number(lat) && Number(lon)) {
        return dispatch(api.area.byLatLon(lat, lon)).then((result) => {
          const areas = result.data.areas; // At most 24 iterations
          let matchedArea;
          if (isEmpty(areas)) {
            console.warn('api.area.byLatLon valid lat & lon but no locations found.');
            throw new Error("Sorry, we couldn't find that location.");
          }

          const breadcrumbs = [];
          const stateArea = find(areas, { type: 'state' });
          if (stateArea) {
            breadcrumbs.push(assign({}, stateArea));
          }

          areaTypeMustMatch.forEach((type) => {
            const typeArea = find(areas, { type });
            if (typeArea) {
              breadcrumbs.push(assign({}, typeArea));
              if (!matchedArea) {
                matchedArea = typeArea;
              }
            }
          });

          // add in the county
          areas.forEach((area) => {
            if (area.type === 'county') {
              matchedArea.county = area.county;
            }
          });

          // set all returned areas as the breadcrumbs (this should be returned by the api)
          matchedArea.breadcrumbs = breadcrumbs;

          return new Area(matchedArea);
        });
      } else {
        return Promise.resolve({});
      }
    };
  },
  getBestFitArea(options) {
    return (dispatch) => {
      const opts = options || {};
      const minLat = opts.minLat;
      const maxLat = opts.maxLat;
      const minLon = opts.minLon;
      const maxLon = opts.maxLon;
      if (Number(minLat) && Number(maxLat) && Number(minLon) && Number(maxLon)) {
        return dispatch(api.area.bestFit(minLat, maxLat, minLon, maxLon)).then((result) => {
          const area = result.data;
          if (!area) {
            logger?.warn('Area of desired type was not returned for AreaActions#getBestFitArea');
            return { err: 'Area of desired type was not returned for AreaActions#getBestFitArea' };
          }
          return new Area(area);
        });
      }
    };
  },
  checkForBadAreaResponse({ area, originalAreaResourceId, originalPath }) {
    return (dispatch) => {
      if (!area || (!area && !originalAreaResourceId && !originalPath)) {
        dispatch(AppActions.setAppStoreBool('isValidAreaPage', false));
        return {
          error: true,
          changeHttpStatus: 404,
        };
      } else if (
        originalAreaResourceId &&
        originalPath &&
        area.resourceId.toUpperCase() !== originalAreaResourceId.toUpperCase()
      ) {
        const newPath = originalPath.replace(originalAreaResourceId, area.resourceId);

        return {
          error: true,
          redirect: true,
          to: newPath,
          changeHttpStatus: 301,
        };
      }

      return area;
    };
  },
  loadAreaPage(areaResourceId, isMapView = true) {
    return function (dispatch, getState) {
      const promises = [];
      const state = getState();
      const { pathname } = state.location.current;

      promises.push(
        dispatch(AreaActions.getAreaByResourceId(areaResourceId))
          .then((area) => {
            return dispatch(
              AreaActions.checkForBadAreaResponse({
                area,
                originalAreaResourceId: areaResourceId,
                originalPath: pathname,
              }),
            );
          })
          .then((checkedArea) => {
            if (checkedArea.resourceId) {
              // pre-emptively set area
              return dispatch(AreaActions.setCurrentArea({ area: checkedArea }));
            } else {
              return checkedArea;
            }
          })
          .catch((error) => {
            logger?.warn({
              error,
              msg: 'AreaActions.loadAreaPage#getAreaByResourceId',
              pathname,
              resourceId: areaResourceId,
              requestId: state.app.requestId,
            });
          }),
      );
      promises.push(dispatch(AreaActions.loadFacts(areaResourceId)));
      promises.push(dispatch(AreaActions.loadFactsByBeds(areaResourceId)));
      promises.push(dispatch(AreaActions.loadArticles(areaResourceId)));

      return Promise.all(promises)
        .then((response = []) => {
          const [areaResponse] = response;
          if (areaResponse.error) {
            return areaResponse;
          } else {
            const area = areaResponse;
            dispatch(AreaActions.setCurrentArea({ area, isMapView }));
            return area;
          }
        })
        .catch((error) => {
          logger?.warn({
            error: {
              stack: error.stack,
              message: error.message,
            },
            msg: 'AreaActions.loadAreaPage#Promise.all',
            pathname,
            resourceId: areaResourceId,
            requestId: state.app.requestId,
          });
        });
    };
  },
  setCurrentArea({ area, shouldSetCache = true, status, isMapView = true }) {
    return (dispatch, getState) => {
      const { app } = getState();
      const isMobile = app.device.isMobile;

      if (!area) {
        console.error('setCurrentArea area not defined');
        return false;
      }
      if (status === 'DATA_NOT_FOUND') {
        console.error('setCurrentArea area not found. should 404');
        dispatch(AppActions.setAppStoreBool('isValidAreaPage', false));
        return false;
      }

      dispatch(AppActions.setAppStoreBool('isValidAreaPage', true));
      dispatch({
        type: constants.LOAD_AREA_SUCCESS,
        payload: {
          area,
        },
      });

      if (isMobile && !isMapView) {
        dispatch(UserSearchActions.updateRecentSearch());
      }

      if (shouldSetCache) {
        setCache('area', area.resourceId, area);
      }
      return Promise.resolve(area);
    };
  },
  getAndSetAreaByResourceId(areaResourceId) {
    return function (dispatch) {
      return dispatch(AreaActions.getAreaByResourceId(areaResourceId)).then((area) => {
        return dispatch(AreaActions.setCurrentArea({ area }));
      });
    };
  },
  loadFacts(areaResourceId) {
    return function (dispatch, getState) {
      const state = getState();
      const key = areaResourceId;
      let facts = fromCache('facts', key);

      if (!areaResourceId) {
        return Promise.resolve(false);
      }

      if (facts) {
        return dispatch({
          type: constants.LOAD_FACTS,
          payload: {
            facts,
          },
        });
      } else {
        return dispatch(api.area.facts(areaResourceId))
          .then((result) => {
            if (result.data) {
              facts = new Facts(result.data);
              setCache('facts', key, facts);
              return dispatch({
                type: constants.LOAD_FACTS,
                payload: {
                  facts,
                },
              });
            } else {
              logError({
                error: `loadFacts data not found for ${areaResourceId}`,
                errorType: 'AreaActionsError',
                context: {
                  traceId: state.location.ssrEntry.traceId,
                },
              });
            }
          })
          .catch((err) => {
            logError({
              error: err,
              errorType: 'AreaActionsError',
              context: {
                message: 'loadFacts failed',
                traceId: state.location.ssrEntry.traceId,
              },
            });
          });
      }
    };
  },
  loadFactsByBeds(areaResourceId) {
    return function (dispatch, getState) {
      const state = getState();
      const key = areaResourceId;
      let factsByBeds = fromCache('factsByBeds', key);
      if (!areaResourceId) {
        return Promise.resolve(false);
      }

      if (factsByBeds) {
        return dispatch({
          type: constants.LOAD_FACTS_BY_BEDS,
          payload: {
            factsByBeds,
          },
        });
      } else {
        return dispatch(api.area.factsByBeds(areaResourceId))
          .then((result) => {
            if (result.data) {
              factsByBeds = new FactsByBeds(result.data);
              setCache('factsByBeds', key, factsByBeds);
              return dispatch({
                type: constants.LOAD_FACTS_BY_BEDS,
                payload: {
                  factsByBeds,
                },
              });
            } else {
              logError({
                error: `loadFactsByBeds data not found for ${areaResourceId}`,
                errorType: 'AreaActionsError',
                context: {
                  traceId: state.location.ssrEntry.traceId,
                },
              });
            }
          })
          .catch((err) => {
            logError({
              error: err,
              errorType: 'AreaActionsError',
              context: {
                message: 'loadFactsByBeds failed',
                traceId: state.location.ssrEntry.traceId,
              },
            });
          });
      }
    };
  },
  loadArticles(areaResourceId) {
    return function (dispatch) {
      const key = areaResourceId;
      let articles = fromCache('articles', key);

      if (!areaResourceId) {
        return Promise.resolve(false);
      }

      if (articles) {
        return dispatch({
          type: constants.LOAD_ARTICLES,
          payload: {
            articles,
          },
        });
      } else {
        return dispatch(api.area.articles(areaResourceId))
          .then((result) => {
            if (result.data && result.data.length) {
              articles = result.data.map((article) => new Article(article));
              setCache('articles', key, articles);
              return dispatch({
                type: constants.LOAD_ARTICLES,
                payload: {
                  articles,
                },
              });
            } else {
              logger?.info({ id: areaResourceId }, 'Area had no articles');
              return dispatch({
                type: constants.LOAD_ARTICLES,
                payload: {
                  articles: [],
                },
              });
            }
          })
          .catch(() => {
            return dispatch({
              type: constants.LOAD_ARTICLES,
              payload: {
                key: areaResourceId,
                articles: [],
              },
            });
          });
      }
    };
  },
  loadWithin(type, areaId) {
    return function (dispatch) {
      const key = type + areaId;
      let areas = fromCache('within', key);

      if (!areaId) {
        return new Promise((resolve) => {
          logger?.debug('AreaActions#loadWithin -> No areaId passed');
          resolve(false);
        });
      }

      if (areas) {
        return dispatch({
          type: constants.LOAD_WITHIN_AREAS,
          payload: {
            within: areas,
          },
        });
      } else {
        return dispatch(api.area.within(type, areaId)).then((result) => {
          areas = new RelatedAreas({ [type]: processAreasResponse(result.data.areas) });
          setCache('within', key, areas);

          return dispatch({
            type: constants.LOAD_WITHIN_AREAS,
            payload: {
              within: areas,
            },
          });
        });
      }
    };
  },
  getAreaGeoJson(area) {
    return (dispatch, getState) => {
      area = area || getState().area.area;
      if (isEmpty(area)) {
        return;
      }

      return dispatch(api.area.getEncodedBoundary(area.id))
        .then((apiResponse = {}) => {
          if (apiResponse.data.area && apiResponse.data.boundary) {
            const geoJson = new GeoJson(apiResponse.data);
            AreaBoundaryCache.add(geoJson);
          }
        })
        .catch(() => {
          // getEncodedBoundary now returns an error if an area has
          // no boundary data associated with it. So, we need to
          // move defaultBoundingBox logic here.
          logger?.info({ id: area.id }, 'Area had no boundary');

          // Set a default bounding box using area coords.
          const { minLon, maxLon, minLat, maxLat } = area;

          if (minLat === maxLat || minLon === maxLon) {
            return;
          }

          const defaultArea = {
            area,
            boundary: {
              type: 'Polygon',
              coordinates: [
                [
                  [minLat, minLon],
                  [minLat, maxLon],
                  [maxLat, maxLon],
                  [maxLat, minLon],
                  [minLat, minLon],
                ],
              ],
            },
            defaultBoundingBox: true,
          };

          const geoJson = new GeoJson(defaultArea);
          AreaBoundaryCache.add(geoJson);
        });
    };
  },
  loadStatePage({ id, resourceId }) {
    return (dispatch, getState) => {
      dispatch(AppActions.setAppStoreBool('loadStatePageComplete', false));
      return dispatch(api.area.byResourceId(resourceId))
        .then((apiResponse) => {
          const area = new Area(apiResponse.data);
          dispatch({
            type: constants.LOAD_AREA_SUCCESS,
            payload: {
              area,
            },
          });
          return area;
        })
        .then(() => {
          return dispatch(
            ListingEngineActions.fetchListingsByCoords({
              filter: { ...DEFAULT, ...MF_FILTER },
              limit: MAX_LISTINGS_PER_SITEMAP_PAGE,
              channels: constants.HOTPADS_MAIN_PREMIUM,
              shouldUseAreaBoundary: true,
            }),
          );
        })
        .then(() => {
          return dispatch(api.area.htmlSitemap(resourceId)).then((apiResponse) => {
            const data = apiResponse.data;
            const mfListings = getState()?.listings?.listingGroups?.byCoords;
            const sitemap = new StateSitemap(data, mfListings);
            dispatch({
              type: constants.LOAD_HTML_SITEMAP,
              payload: {
                sitemap,
              },
            });
            dispatch(AppActions.setAppStoreBool('loadStatePageComplete', true));
          });
        });
    };
  },
  // note: never called - waiting on V3
  loadAreaBlogPosts(resourceId) {
    return (dispatch) => {
      return dispatch(api.area.blogPostsByResourceId(resourceId)).then((result) => {
        const { data, success } = result;

        if (!success) {
          logError({
            error: `Error in AreaActions#loadAreaBlogPosts for area ${resourceId}`,
            errorType: 'AreaActionsError',
          });
          return false;
        }

        return dispatch({
          type: constants.LOAD_BLOG_POSTS,
          payload: {
            blogPosts: data,
          },
        });
      });
    };
  },
  loadAreaSeoLinks({ resourceId, searchSlug }) {
    return (dispatch) => {
      return dispatch(api.area.seoFooterByResourceId({ resourceId, searchSlug })).then((result) => {
        const { data, success } = result;

        if (!success) {
          logError({
            error: `Error in AreaActions#loadAreaSeoLinks for area ${resourceId}`,
            errorType: 'AreaActionsError',
          });
          return false;
        }

        return dispatch({
          type: constants.LOAD_SEO_FOOTER_LINKS,
          payload: {
            seoFooterLinks: data,
          },
        });
      });
    };
  },
  fetchAreaDataByResourceId(resourceId) {
    return (dispatch, getState) => {
      const isValidAreaPage = getState().app.isValidAreaPage;

      return dispatch(api.area.byResourceId(resourceId))
        .then((result) => {
          const { data = {}, status } = result;
          if (status === 'DATA_NOT_FOUND') {
            logger?.warn('MobileListingActions#fetchAreaDataByResourceId invalid area: ', resourceId);

            // show NotFoundPage
            dispatch(AppActions.setAppStoreBool('isValidAreaPage', false));

            return { error: true };
          }

          if (!isValidAreaPage) {
            dispatch(AppActions.setAppStoreBool('isValidAreaPage', true));
          }

          if (data && data.resourceId) {
            const formattedArea = new Area(data);

            dispatch({
              type: constants.LOAD_AREA_SUCCESS,
              payload: {
                area: formattedArea,
              },
            });
          }

          return Promise.resolve({ success: true });
        })
        .catch((err) => {
          logError({
            error: err,
            errorType: 'AreaActionsError',
            context: 'fetchAreaDataByResourceId failed',
          });
          throw err;
        });
    };
  },
  fetchPriceHistogramData(additionalParams) {
    return (dispatch, getState) => {
      const state = getState();
      const { offset, limit } = additionalParams;
      const apiParams = adapt_reduxToJava({
        filter: state.filter,
        area: {
          id: get(state.area, 'area.id', '117776782'),
          maxLat: get(state.area, 'area.maxLat', 40.917577),
          maxLon: get(state.area, 'area.maxLon', -73.700272),
          minLat: get(state.area, 'area.minLat', 40.477399),
          minLon: get(state.area, 'area.minLon', -74.25909),
        },
        offset,
        limit,
      });
      // NOTE: Polyfill or use Lodash for desktop migration
      const priceHistogramParams = Object.assign(apiParams, { numBuckets: 100 });

      return dispatch(api.listing.priceHistogram(priceHistogramParams))
        .then((res) => {
          if (res.data) {
            const { buckets } = res.data;

            if (buckets) {
              const minPriceIndex = buckets.findIndex((bucket = {}) => bucket.percent >= 5);
              const maxPriceIndex = buckets.findIndex((bucket = {}) => bucket.percent >= 95);
              const minPrice = buckets[minPriceIndex] && buckets[minPriceIndex].min;
              const maxPrice = buckets[maxPriceIndex] && buckets[maxPriceIndex].max;

              dispatch({
                type: constants.FETCH_AREA_MIN_MAX_PRICE,
                payload: {
                  areaMinPrice: minPrice,
                  areaMaxPrice: maxPrice,
                },
              });

              return Promise.resolve({ success: true });
            }
          }
        })
        .catch((err) => {
          logError({
            error: err,
            errorType: 'AreaActionsError',
            context: 'fetchPriceHistogramData failed',
          });
          throw err;
        });
    };
  },
};

export default AreaActions;
