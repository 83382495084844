// @ts-nocheck
/* eslint-enable */
const headerToPhraseMap = {
  'apartments-for-rent': 'apartments for rent',
  'houses-for-rent': 'houses for rent',
  'condos-for-rent': 'condos for rent',
  'affordable-apartments-for-rent': 'affordable apartments for rent',
  'for-rent-by-owner': 'listings for rent by owner',
};

const areaGroupMapper = (areaGroups, header) => {
  return areaGroups
    .filter((areaGroup) => areaGroup.listingCount)
    .map((areaGroup) => {
      return {
        ...areaGroup,
        phrase: `${areaGroup.listingCount} ${headerToPhraseMap[header]}`,
      };
    });
};

const propertyTypeReducer = (acc, curr) => {
  if (curr.areaType === 'city') {
    acc.cities = areaGroupMapper(curr.areaGroups, curr.header);
  }
  if (curr.areaType === 'zip') {
    acc['zip-codes'] = areaGroupMapper(curr.areaGroups, curr.header);
  }
  if (curr.areaType === 'county') {
    acc.counties = areaGroupMapper(curr.areaGroups, curr.header);
  }
  if (curr.areaType === 'neighborhood') {
    acc.neighborhoods = areaGroupMapper(curr.areaGroups, curr.header);
  }
  return acc;
};

const StateSitemap = function (sitemap = [], listings = []) {
  this.apartments = sitemap.filter((item) => item.header === 'apartments-for-rent').reduce(propertyTypeReducer, {});
  this.houses = sitemap.filter((item) => item.header === 'houses-for-rent').reduce(propertyTypeReducer, {});
  this.condos = sitemap.filter((item) => item.header === 'condos-for-rent').reduce(propertyTypeReducer, {});
  this['affordable apartments'] = sitemap
    .filter((item) => item.header === 'affordable-apartments-for-rent')
    .reduce(propertyTypeReducer, {});
  this['for rent by owner'] = sitemap
    .filter((item) => item.header === 'for-rent-by-owner')
    .reduce(propertyTypeReducer, {});
  this['multi-family buildings'] = {
    'multi-family buildings': listings.map((listing) => {
      return {
        name: listing.displayName,
        uriV2: listing.uriV2,
        phrase: `Building in ${listing?.address?.city}`,
      };
    }),
  };
};

export default StateSitemap;
