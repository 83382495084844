class Article {
  areaId: number;
  body: string;
  title: string;

  constructor({ areaId = 0, body = '', title = '' }: { areaId?: number; body?: string; title?: string } = {}) {
    this.areaId = Number(areaId);
    this.body = String(body);
    this.title = String(title);
  }
}

export default Article;
